<template>
	<div class="row" v-if="!pdf">
		<h4 style="margin-top: 1%; margin-bottom: 1%;">Personal data</h4>
		<div class="row" v-if="userIsAdmin && !edit" style="margin-bottom: 1%; margin-left: 0px;">
			<button v-b-tooltip.hover class="btn btn-primary" style="margin: 0 auto; width: 200px;" type="button"
				@click="edit = true"><font-awesome-icon icon="edit" class="white-color" /> Edit Personal Data</button>
		</div>
		<div class="row" v-if="userIsAdmin && edit" style="margin-bottom: 1%; margin-left: 0px;">
			<div class="col-sm-4 col-centered">
				<button v-b-tooltip.hover class="btn btn-secondary"
					style="margin: 0 auto; margin-right: 10px; width: 185px;" type="button"
					@click="cancel()"><font-awesome-icon icon="ban" /> Cancel</button>
				<button v-b-tooltip.hover class="btn btn-primary" style="margin: 0 auto; width: 185px;" type="button"
					@click="editInformation()"><font-awesome-icon icon="save" class="white-color" /> Save
					Changes</button>
			</div>
		</div>
		<div class="analysis-profile-container">
			<div class="row">
				<div class="col-sm-6">
					<div class="col-sm-12 personal-data-container">
						<p class="no-margin-bottom"><b>Name</b></p>
						<template v-if="!edit">
							<p v-if="data.name != ''">{{ data.name }}</p>
							<p v-else>Not available</p>
						</template>
						<b-input v-else v-model="data.name"></b-input>
					</div>
					<div class="col-sm-12 personal-data-container">
						<p class="no-margin-bottom"><b>Description</b></p>
						<template v-if="!edit">
							<p v-if="data.description != ''">{{ data.description }}</p>
							<p v-else>Not available</p>
						</template>
						<textarea v-else class="form-control textarea-message" v-model="data.description"
							id="exampleFormControlTextarea1" rows="3"></textarea>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="col-sm-12 personal-data-container">
						<p class="no-margin-bottom"><b>Email</b></p>
						<template v-if="!edit">
							<p v-if="data.email != ''">{{ data.email }}</p>
							<p v-else>Not available</p>
						</template>
						<b-input v-else v-model="data.email"></b-input>
					</div>
					<div class="col-sm-12 personal-data-container">
						<p class="no-margin-bottom"><b>Phone number</b></p>
						<template v-if="!edit">
							<p v-if="data.phone_number != ''">{{ data.phone_number }}</p>
							<p v-else>Not available</p>
						</template>
						<b-input v-else v-model="data.phone_number"></b-input>
					</div>
					<div class="col-sm-12 personal-data-container">
						<p class="no-margin-bottom"><b>Created at</b></p>
						<p>{{ data.created_at | formatEnglishDate }}</p>
					</div>
				</div>
			</div>
			<hr>
			<div class="row">
				<div class="col-sm-6 profile-info-container">
					<h5 v-if="!edit">Awards</h5>
					<h5 v-else>Awards&nbsp;&nbsp;&nbsp;<font-awesome-icon class="add-icon" icon="plus"
							@click="addItemToAwards()" v-b-tooltip.hover title="Add award" /></h5>
					<template v-if="!edit">
						<p v-if="awards.length == 0">No awards registered</p>
						<ul v-else>
							<li v-for="(award, index) in awards" v-bind:key="'award' + index">
								{{ award }}
							</li>
						</ul>
					</template>
					<div class="row items-to-skill" v-else>
						<b-table ref="awardsTable" id="awardsTable"
							:fields="[{ key: 'award', label: 'Award' }, { key: 'options', label: '' }]" :items="awards"
							responsive="sm" v-if="awards.length != 0">
							<template #cell(award)="data">
								<b-input v-model="awards[data.index]"></b-input>
							</template>
							<template #cell(options)="data">
								<button v-b-tooltip.hover title="Delete award" class="btn btn-secondary"
									@click="showDeleteModal(data.item, data.index, 'award', null)"
									type="button"><font-awesome-icon icon="trash" /></button>
							</template>
						</b-table>
						<p v-else>No awards defined yet.</p>
					</div>
				</div>
				<div class="col-sm-6 profile-info-container">
					<h5 v-if="!edit">Certifications</h5>
					<h5 v-else>Certifications&nbsp;&nbsp;&nbsp;<font-awesome-icon class="add-icon" icon="plus"
							@click="addItemToCertifications()" v-b-tooltip.hover title="Add certification" /></h5>
					<template v-if="!edit">
						<p v-if="certifications.length == 0">No certifications registered</p>
						<ul v-else>
							<li v-for="(certification, index) in certifications" v-bind:key="'certification' + index">
								{{ certification }}
							</li>
						</ul>
					</template>
					<div class="row items-to-skill" v-else>
						<b-table ref="certificationsTable" id="certificationsTable"
							:fields="[{ key: 'certification', label: 'Certification' }, { key: 'options', label: '' }]"
							:items="certifications" responsive="sm" v-if="certifications.length != 0">
							<template #cell(certification)="data">
								<b-input v-model="certifications[data.index]"></b-input>
							</template>
							<template #cell(options)="data">
								<button v-b-tooltip.hover title="Delete certification" class="btn btn-secondary"
									@click="showDeleteModal(data.item, data.index, 'certification', null)"
									type="button"><font-awesome-icon icon="trash" /></button>
							</template>
						</b-table>
						<p v-else>No certifications defined yet.</p>
					</div>
				</div>
			</div>
			<hr>
			<div class="row">
				<div class="col-sm-6 profile-info-container">
					<h5 v-if="!edit">Keywords</h5>
					<h5 v-else>Keywords&nbsp;&nbsp;&nbsp;<font-awesome-icon class="add-icon" icon="plus"
							@click="addItemToKeywords()" v-b-tooltip.hover title="Add keyword" /></h5>
					<template v-if="!edit">
						<p v-if="keywords.length == 0">No keywords registered</p>
						<ul v-else>
							<li v-for="(keyword, index) in keywords" v-bind:key="'keyword' + index">
								{{ keyword }}
							</li>
						</ul>
					</template>
					<div class="row items-to-skill" v-else>
						<b-table ref="keywordsTable" id="keywordsTable"
							:fields="[{ key: 'keyword', label: 'Keyword' }, { key: 'options', label: '' }]"
							:items="keywords" responsive="sm" v-if="keywords.length != 0">
							<template #cell(keyword)="data">
								<b-input v-model="keywords[data.index]"></b-input>
							</template>
							<template #cell(options)="data">
								<button v-b-tooltip.hover title="Delete keyword" class="btn btn-secondary"
									@click="showDeleteModal(data.item, data.index, 'keyword', null)"
									type="button"><font-awesome-icon icon="trash" /></button>
							</template>
						</b-table>
						<p v-else>No keywords defined yet.</p>
					</div>
				</div>
				<div class="col-sm-6 profile-info-container">
					<h5 v-if="!edit">Other links</h5>
					<h5 v-else>Other links&nbsp;&nbsp;&nbsp;<font-awesome-icon class="add-icon" icon="plus"
							@click="addItemToOtherLinks()" v-b-tooltip.hover title="Add link" /></h5>
					<template v-if="!edit">
						<p v-if="links.length == 0">No links registered</p>
						<ul v-else>
							<li v-for="(link, index) in links" v-bind:key="'link' + index">
								<a :href="link" target="_blank">{{ link }}</a>
							</li>
						</ul>
					</template>
					<div class="row items-to-skill" v-else>
						<b-table ref="linksTable" id="linksTable"
							:fields="[{ key: 'link', label: 'Link' }, { key: 'options', label: '' }]" :items="links"
							responsive="sm" v-if="links.length != 0">
							<template #cell(link)="data">
								<b-input v-model="links[data.index]"></b-input>
							</template>
							<template #cell(options)="data">
								<button v-b-tooltip.hover title="Delete link" class="btn btn-secondary"
									@click="showDeleteModal(data.item, data.index, 'link', null)"
									type="button"><font-awesome-icon icon="trash" /></button>
							</template>
						</b-table>
						<p v-else>No links defined yet.</p>
					</div>
				</div>
			</div>
			<hr>
			<div class="row">
				<div class="col-sm-6 profile-info-container" v-for="(skillValues, skillName, index) in skills"
					v-bind:key="skillName">
					<h5 v-if="!edit">{{ skillName.charAt(0).toUpperCase() + skillName.replaceAll("_", " ").slice(1) }}
					</h5>
					<h5 v-else>{{ skillName.charAt(0).toUpperCase() + skillName.replaceAll("_", "
						").slice(1)}}&nbsp;&nbsp;&nbsp;<font-awesome-icon class="add-icon" icon="plus"
							@click="addItemToSkills(skillName)" v-b-tooltip.hover title="Add item" /></h5>
					<template v-if="!edit">
						<p v-if="skillValues.length == 0">Not available</p>
						<ul v-else>
							<li v-for="(skill, index) in skillValues" v-bind:key="'skill' + index">
								{{ skill.charAt(0).toUpperCase() + skill.slice(1) }}
							</li>
						</ul>
					</template>
					<div class="row items-to-skill" v-else>
						<b-table ref="skillsTable" id="skillsTable"
							:fields="[{ key: 'item', label: 'Item' }, { key: 'options', label: '' }]"
							:items="skillValues" responsive="sm" v-if="skillValues.length != 0">
							<template #cell(item)="data">
								<b-input v-model="skillValues[data.index]"></b-input>
							</template>
							<template #cell(options)="data">
								<button v-b-tooltip.hover title="Delete item" class="btn btn-secondary"
									@click="showDeleteModal(data.item, data.index, 'skill', skillName)"
									type="button"><font-awesome-icon icon="trash" /></button>
							</template>
						</b-table>
						<p v-else>No items defined yet.</p>
					</div>
				</div>
			</div>
			<hr>
			<div class="row">
				<div class="col-sm-6 profile-info-container">
					<h5 v-if="!edit">Education</h5>
					<h5 v-else>Education&nbsp;&nbsp;&nbsp;<font-awesome-icon class="add-icon" icon="plus"
							@click="addItemToEducation()" v-b-tooltip.hover title="Add education" /></h5>
					<template v-if="!edit">
						<p v-if="education.length == 0">Not available</p>
						<table class="table" v-else>
							<thead>
								<th>Institution</th>
								<th>Description</th>
							</thead>
							<tbody>
								<tr v-for="(item, index) in education" v-bind:key="'item' + index">
									<td>{{ item.study_site }}</td>
									<td v-if="item.type_education_date != null">{{ item.type_education_date }}</td>
									<td v-else>{{ item.type_education }}</td>
								</tr>
							</tbody>
						</table>
					</template>
					<div class="row items-to-skill" v-else>
						<b-table ref="educationTable" id="educationTable"
							:fields="[{ key: 'study_site', label: 'Institution' }, { key: 'type_education_date', label: 'Description' }, { key: 'options', label: '' }]"
							:items="education" responsive="sm" v-if="education.length != 0">
							<template #cell(study_site)="data">
								<b-input v-model="data.item.study_site"></b-input>
							</template>
							<template #cell(type_education_date)="data">
								<b-input v-model="data.item.type_education_date"></b-input>
							</template>
							<template #cell(options)="data">
								<button v-b-tooltip.hover title="Delete education" class="btn btn-secondary"
									@click="showDeleteModal(data.item, data.index, 'education', null)"
									type="button"><font-awesome-icon icon="trash" /></button>
							</template>
						</b-table>
						<p v-else>No links defined yet.</p>
					</div>
				</div>
				<div class="col-sm-6 profile-info-container">
					<h5 v-if="!edit">Professional Career</h5>
					<h5 v-else>Professional Career&nbsp;&nbsp;&nbsp;<font-awesome-icon class="add-icon" icon="plus"
							@click="addItemToJobs()" v-b-tooltip.hover title="Add job" /></h5>
					<template v-if="!edit">
						<p v-if="jobs.length == 0">Not available</p>
						<table class="table" v-else>
							<thead>
								<th>Job</th>
								<th>Company</th>
								<th>Date</th>
								<th>Duration</th>
							</thead>
							<tbody>
								<tr v-for="(item, index) in jobs" v-bind:key="index + '-' + item.job">
									<td>{{ item.job }}</td>
									<td>{{ item.company }}</td>
									<td>{{ item.date }}</td>
									<td>{{ item.duration.years }} years, {{ item.duration.months }} months</td>
								</tr>
							</tbody>
						</table>
					</template>
					<div class="row items-to-skill" v-else>
						<b-table ref="jobsTable" id="jobsTable"
							:fields="[{ key: 'job', label: 'Job' }, { key: 'company', label: 'Company' }, { key: 'date', label: 'Date' }, { key: 'years', label: 'Years' }, { key: 'months', label: 'Months' }, { key: 'options', label: '' }]"
							:items="jobs" responsive="sm" v-if="jobs.length != 0">
							<template #cell(job)="data">
								<b-input v-model="data.item.job"></b-input>
							</template>
							<template #cell(company)="data">
								<b-input v-model="data.item.company"></b-input>
							</template>
							<template #cell(date)="data">
								<b-input v-model="data.item.date"></b-input>
							</template>
							<template #cell(years)="data">
								<b-input v-model="data.item.duration.years"></b-input>
							</template>
							<template #cell(months)="data">
								<b-input v-model="data.item.duration.months"></b-input>
							</template>
							<template #cell(options)="data">
								<button v-b-tooltip.hover title="Delete job" class="btn btn-secondary"
									@click="showDeleteModal(data.item, data.index, 'job', null)"
									type="button"><font-awesome-icon icon="trash" /></button>
							</template>
						</b-table>
						<p v-else>No jobs defined yet.</p>
					</div>
				</div>
			</div>
			<hr>
			<div class="row">
				<div class="col-sm-6 profile-info-container">
					<h5 v-if="!edit">Languages</h5>
					<h5 v-else>Languages&nbsp;&nbsp;&nbsp;<font-awesome-icon class="add-icon" icon="plus"
							@click="addItemToLanguages()" v-b-tooltip.hover title="Add language" /></h5>
					<template v-if="!edit">
						<p v-if="languages.length == 0">Not available</p>
						<table class="table" v-else>
							<thead>
								<th>Language</th>
								<th>Level</th>
							</thead>
							<tbody>
								<tr v-for="item in languages" v-bind:key="item.language">
									<td>{{ item.language }}</td>
									<td>{{ item.level }}</td>
								</tr>
							</tbody>
						</table>
					</template>
					<div class="row items-to-skill" v-else>
						<b-table ref="languagesTable" id="languagesTable"
							:fields="[{ key: 'language', label: 'Language' }, { key: 'level', label: 'Level' }, { key: 'options', label: '' }]"
							:items="languages" responsive="sm" v-if="languages.length != 0">
							<template #cell(language)="data">
								<b-input v-model="data.item.language"></b-input>
							</template>
							<template #cell(level)="data">
								<b-input v-model="data.item.level"></b-input>
							</template>
							<template #cell(options)="data">
								<button v-b-tooltip.hover title="Delete language" class="btn btn-secondary"
									@click="showDeleteModal(data.item, data.index, 'language', null)"
									type="button"><font-awesome-icon icon="trash" /></button>
							</template>
						</b-table>
						<p v-else>No languages defined yet.</p>
					</div>
				</div>
				<div class="col-sm-6 profile-info-container">
					<h5>Years of Experience</h5>
					<template v-if="!edit">
						<p style="margin-left: 11px;">{{ experience.years }} years and {{ experience.months }} months
						</p>
					</template>
					<div class="row items-to-skill" v-else>
						<b-table ref="jobsTable" id="jobsTable"
							:fields="[{ key: 'years', label: 'Years' }, { key: 'months', label: 'Months' }]"
							:items="[experience]" responsive="sm">
							<template #cell(years)="data">
								<b-input v-model="data.item.years"></b-input>
							</template>
							<template #cell(months)="data">
								<b-input v-model="data.item.months"></b-input>
							</template>
						</b-table>
					</div>
				</div>
			</div>
		</div>

		<div id="message" v-if="message" class="alert" :class="success ? 'alert-success' : 'alert-danger'">
			{{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross" icon="times" />
			</b-button>
		</div>


		<b-modal id="delete-modal" ref="delete-modal" title="Delete-modal" hide-footer>
			<template #modal-title>
				Delete item
			</template>
			<div>
				<p>Do you really want to delete the selected item? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
				@click="$bvModal.hide('delete-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="deleteItem()" value="Yes">
		</b-modal>
	</div>
	<section class="remove-just-this" v-else>
		<section>
			<h4
				style="margin-top: 0%; text-align: left;border-bottom: 3px solid;padding-bottom: 9px; margin-bottom: 14px; font-size: 18.66px; padding-left: 0px !important">
				Personal Data</h4>
			<div class="row">
				<div class="col-sm-12 pt-0 pb-2" style="margin-left: 0px;">
					<h5 class="mb-0"
						style="padding: 0 !important; font-size: 14.66px; margin-bottom: 0px; color: #13283B;">
						General information</h5>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-5" style="padding-left: 13px !important; padding-right: 13px !important;">
					<div class="row">
						<div class="col-sm-3 pt-0 pb-0">
							<p class="no-margin-bottom"
								style="text-align: left; font-size: 14.66px; margin-bottom: 0px; color: #13283B;">
								<b>Name:</b>
							</p>
						</div>
						<div class="col-sm-9 pt-0 pb-0" style="word-break: break-all;">
							<template v-if="!anonymize">
								<p v-if="data.name != ''"
									style="text-align: left; font-size: 14.66px; margin-bottom: 0px">{{ data.name }}</p>
								<p v-else style="text-align: left;">Not available</p>
							</template>
							<p v-else style="text-align: left;"><i>Anonymized</i></p>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-3 pt-0 pb-0">
							<p class="no-margin-bottom"
								style="text-align: left; font-size: 14.66px; margin-bottom: 0px; color: #13283B;">
								<b>Email:</b>
							</p>
						</div>
						<div class="col-sm-9 pt-0 pb-0" style="word-break: break-all;">
							<template v-if="!anonymize">
								<p v-if="data.email != ''"
									style="text-align: left; font-size: 14.66px; margin-bottom: 0px">{{ data.email }}
								</p>
								<p v-else style="text-align: left;">Not available</p>
							</template>
							<p v-else style="text-align: left;"><i>Anonymized</i></p>
						</div>
					</div>
				</div>
				<div class="col-sm-7">
					<div class="row">
						<div class="col-sm-6 pt-0 pb-0">
							<p class="no-margin-bottom"
								style="text-align: left; font-size: 14.66px; margin-bottom: 0px; color: #13283B;">
								<b>Experience years:</b>
							</p>
						</div>
						<div class="col-sm-6 pt-0 pb-0">
							<p style="text-align: left; font-size: 14.66px; margin-bottom: 0px">{{ experience.years }}
								years
								and {{ experience.months }} months</p>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6 pt-0 pb-0">
							<p class="no-margin-bottom"
								style="text-align: left; font-size: 14.66px; margin-bottom: 0px; color: #13283B;">
								<b>Phone:</b>
							</p>
						</div>
						<div class="col-sm-6 pt-0 pb-0">
							<template v-if="!anonymize">
								<p v-if="data.phone_number != ''"
									style="text-align: left; font-size: 14.66px; margin-bottom: 0px">
									{{ data.phone_number }}</p>
								<p v-else style="text-align: left;">Not available</p>
							</template>
							<p v-else style="text-align: left;"><i>Anonymized</i></p>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-12" style="padding-left: 13px !important; padding-right: 13px !important;">
					<div class="row">
						<div class="col-sm-12 pt-0 pb-0">
							<p class="no-margin-bottom"
								style="text-align: left; font-size: 14.66px; margin-bottom: 0px; color: #13283B;">
								<b>Description:</b>
							</p>
						</div>
						<div class="col-sm-12 pt-0 pb-0">
							<template v-if="!anonymize">
								<p v-if="data.description != ''"
									style="text-align: left; font-size: 14.66px; margin-bottom: 0px">
									{{ data.description }}</p>
								<p v-else style="text-align: left;">Not available</p>
							</template>
							<p v-else style="text-align: left;"><i>Anonymized</i></p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section>
			<div class="row">
				<div class="col-sm-12" style="padding-left: 13px !important; padding-right: 13px !important;">
					<div class="row">
						<div class="col-sm-12 pb-1">
							<h5 class="mb-0"
								style="padding: 0 !important; font-size: 14.66px; margin-bottom: 0px; padding-top: 5px; color: #13283B;">
								Education</h5>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-4 pt-0 pb-0" v-if="education.length == 0">
							<p class="no-margin-bottom"
								style="text-align: left; font-size: 14.66px; margin-bottom: 0px">No
								education registered</p>
						</div>
						<template v-else>
							<ul class="col-sm-12 pt-0 pb-0" v-for="(item, index) in education"
								v-bind:key="'education' + index" style="margin-bottom: 0px">
								<li v-if="item.type_education_date != null" class="no-margin-bottom"
									style="list-style: none; text-align: left; font-size: 14.66px; margin-left: 0px; margin-bottom: 0px">
									&bull; {{ item.type_education_date }} in {{ item.study_site }}</li>
								<li v-else class="no-margin-bottom"
									style="list-style: none;text-align: left; font-size: 14.66px; margin-left: 0px; margin-bottom: 0px">
									&bull; {{ item.type_education }} in {{ item.study_site }}</li>
							</ul>
						</template>
					</div>
				</div>
			</div>
		</section>
		<section>
			<div class="row">
				<div class="col-sm-12" style="padding-left: 13px !important; padding-right: 13px !important;">
					<div class="row">
						<div class="col-sm-12 pb-1">
							<h5 class="mb-0"
								style="padding: 0 !important; font-size: 14.66px; margin-bottom: 0px; padding-top: 5px; color: #13283B;">
								Professional Career</h5>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-4 pt-0 pb-0" v-if="jobs.length == 0">
							<p class="no-margin-bottom"
								style="text-align: left; font-size: 14.66px; margin-bottom: 0px">No
								professional career registered</p>
						</div>
						<template v-else>
							<ul v-if="!anonymize" class="col-sm-12 pt-0 pb-0"
								style="padding-left: 0px; margin-bottom: 0px">
								<li v-for="item in jobs" v-bind:key="item"
									style="list-style: none; text-align: left; margin-left: 13px; font-size: 14.66px; margin-bottom: 0px">
									&bull; {{ item.date }}. {{ item.job }} / ({{ item.company }}) during
									{{ item.duration.years }} years, {{ item.duration.months }} months</li>
							</ul>
							<ul v-else class="col-sm-12 pt-0 pb-0" style="padding-left: 0px; margin-bottom: 0px">
								<li v-for="item in jobs" v-bind:key="item"
									style="list-style: none; text-align: left; margin-left: 13px; font-size: 14.66px; margin-bottom: 0px">
									&bull; {{ item.job }} during {{ item.duration.years }} years, {{
		item.duration.months }}
									months</li>
							</ul>
						</template>
					</div>
				</div>
			</div>
		</section>

		<template v-for="(skillValues, skillName, index) in skills">
			<section>
				<div class="row" v-bind:key="skillName">
					<div class="col-sm-12" style="padding-left: 13px !important; padding-right: 13px !important;">
						<div class="row">
							<div class="col-sm-12 pb-1">
								<h5 class="mb-0"
									style="padding: 0 !important; font-size: 14.66px; margin-bottom: 0px; color: #13283B; padding-top: 5px">
									{{ skillName.charAt(0).toUpperCase() + skillName.replaceAll("_", " ").slice(1) }}
								</h5>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-12 pt-0 pb-0" v-if="skillValues.length == 0">
								<p class="no-margin-bottom"
									style="text-align: left; margin-left: 13px; font-size: 14.66px; margin-bottom: 0px">
									No skills registered</p>
							</div>
							<template v-else>
								<ul class="pt-0 pb-0" style="padding-left: 0px; margin-bottom: 0px">
									<li v-for="skill in skillValues" v-bind:key="skill"
										class="col-sm-3 no-margin-bottom"
										style="float: left; display: inline; text-align: left; margin-left: 13px; font-size: 14.66px; margin-bottom: 0px">
										&bull; {{ skill.charAt(0).toUpperCase() + skill.slice(1) }}</li>
								</ul>
							</template>
						</div>
					</div>
				</div>
			</section>
		</template>
		<section>
			<div class="row">
				<div class="col-sm-12" style="padding-left: 13px !important; padding-right: 13px !important;">
					<div class="row">
						<div class="col-sm-12 pb-1">
							<h5 class="mb-0"
								style="padding: 0 !important; font-size: 14.66px; margin-bottom: 0px; color: #13283B; padding-top: 5px">
								Languages</h5>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-4 pt-0 pb-0" v-if="languages.length == 0">
							<p class="no-margin-bottom"
								style="text-align: left; margin-left: 0px; font-size: 14.66px; margin-bottom: 0px">No
								languages
								registered</p>
						</div>
						<template v-else>
							<ul class="col-sm-12 pt-0 pb-0" style="margin-bottom: 0px">
								<li v-for="language in languages" v-bind:key="language" class="no-margin-bottom"
									style="list-style: none; text-align: left;  margin-left: 0px; font-size: 14.66px; margin-bottom: 0px">
									&bull; {{ language.language }} (Level: {{ language.level }})</li>
							</ul>
						</template>
					</div>
				</div>
			</div>
		</section>
		<section>
			<div class="row">
				<div class="col-sm-12" style="padding-left: 13px !important; padding-right: 13px !important;">
					<div class="row">
						<div class="col-sm-12 pb-1">
							<h5 class="mb-0"
								style="padding: 0 !important; font-size: 14.66px; margin-bottom: 0px; color: #13283B; padding-top: 5px">
								Awards</h5>
							<h6 style="font-size: 10.66px; margin-bottom: 0px">(Showing 5 of them, for more information
								check
								interactive profile)</h6>
						</div>
					</div>
					<div class="row">
						<template v-if="!anonymize">
							<div class="col-sm-4 pt-0 pb-0" v-if="awards.length == 0">
								<p class="no-margin-bottom"
									style="text-align: left; margin-left: 0px; font-size: 14.66px; margin-bottom: 0px">
									No awards registered</p>
							</div>
							<template v-else>
								<ul class="col-sm-12 pt-0 pb-0" style="padding-left: 0px; margin-bottom: 0px">
									<li v-for="award in awards.slice(0, 5)" v-bind:key="award"
										style="list-style: none; text-align: left; margin-left: 13px; font-size: 14.66px; margin-bottom: 0px">
										&bull; {{ award.replace('-', '') }}</li>
								</ul>
							</template>
						</template>
						<p v-else style="text-align: left;"><i>Anonymized</i></p>
					</div>
				</div>
			</div>
		</section>
		<section>
			<div class="row">
				<div class="col-sm-12" style="padding-left: 13px !important; padding-right: 13px !important;">
					<div class="row">
						<div class="col-sm-12 pb-1">
							<h5 class="mb-0"
								style="padding: 0 !important; font-size: 14.66px; margin-bottom: 0px; color: #13283B; padding-top: 5px">
								Keywords</h5>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-4 pt-0 pb-0" v-if="keywords.length == 0">
							<p class="no-margin-bottom"
								style="text-align: left; margin-left: 0px; font-size: 14.66px; margin-bottom: 0px">No
								keywords
								registered</p>
						</div>
						<template v-else>
							<div class="col-sm-4 pt-0 pb-0" v-for="keyword in keywords" v-bind:key="keyword">
								<p class="no-margin-bottom"
									style="text-align: left; margin-left: 0px; font-size: 14.66px; margin-bottom: 0px">
									{{ keyword }}</p>
							</div>
						</template>
					</div>
				</div>
			</div>
		</section>
	</section>
</template>

<script>
import ProfileAnalysisDataService from "@/services/ProfileAnalysisDataService";
import $ from 'jquery'

export default {
	name: "PersonalData",
	data() {
		return {
			edit: false,
			selectedArray: null,
			selectedItem: null,
			selectedItemIndex: null,
			selectedSkillType: null,
			message: "",
			success: false
		}
	},
	props: {
		data: Object,
		awards: Array,
		certifications: Array,
		keywords: Array,
		links: Array,
		skills: Object,
		education: Array,
		jobs: Array,
		languages: Array,
		experience: Object,
		userIsAdmin: Boolean,
		anonymize: Boolean,
		pdf: {
			type: Boolean,
			default: false
		}
	},
	mounted() {
		if (this.pdf) {
			var cnt = $(".remove-just-this").contents();
			$(".remove-just-this").replaceWith(cnt);
		}
	},
	methods: {
		redirectExternalLink(link) {
			if (link.indexOf('https://') <= -1 || link.indexOf('http://') <= -1) {
				link = 'https://' + link
			}
			window.open(
				link,
				'_blank'
			);
		},
		addItemToAwards() {
			const newRow = ""
			this.awards.unshift(newRow)
		},
		addItemToCertifications() {
			const newRow = ""
			this.certifications.unshift(newRow)
		},
		addItemToKeywords() {
			const newRow = ""
			this.keywords.unshift(newRow)
		},
		addItemToOtherLinks() {
			const newRow = ""
			this.links.unshift(newRow)
		},
		addItemToEducation() {
			const newRow = { study_site: "", type_education_date: "" }
			this.education.unshift(newRow)
		},
		addItemToJobs() {
			const newRow = { job: "", description: "", company: "", date: "", duration: { years: "", months: "" } }
			this.jobs.unshift(newRow)
		},
		addItemToLanguages() {
			const newRow = { language: "", level: "" }
			this.languages.unshift(newRow)
		},
		addItemToSkills(name) {
			const newRow = ""
			this.skills[name].unshift(newRow)
		},
		showDeleteModal(item, index, tipo, skillType) {
			this.selectedArray = tipo
			this.selectedItem = item
			this.selectedItemIndex = index
			this.selectedSkillType = skillType
			this.$refs['delete-modal'].show();
		},
		deleteItem() {
			if (this.selectedArray === 'award') this.awards.splice(this.selectedItemIndex, 1);
			else if (this.selectedArray === 'certification') this.certifications.splice(this.selectedItemIndex, 1);
			else if (this.selectedArray === 'keyword') this.keywords.splice(this.selectedItemIndex, 1);
			else if (this.selectedArray === 'link') this.links.splice(this.selectedItemIndex, 1);
			else if (this.selectedArray === 'education') this.education.splice(this.selectedItemIndex, 1);
			else if (this.selectedArray === 'job') this.jobs.splice(this.selectedItemIndex, 1);
			else if (this.selectedArray === 'language') this.languages.splice(this.selectedItemIndex, 1);
			else if (this.selectedArray === 'skill') this.skills[this.selectedSkillType].splice(this.selectedItemIndex, 1);

			this.$refs['delete-modal'].hide();
		},
		editInformation() {
			console.log('editInformation')
			this.$emit('recalculate', true)
			this.success = false

			let personalData = { ...this.data }

			personalData.awards = JSON.stringify(this.awards)
			personalData.certifications = JSON.stringify(this.certifications)
			personalData.keywords = JSON.stringify(this.keywords)
			personalData.links = JSON.stringify(this.links)
			personalData.education = JSON.stringify(this.education)
			personalData.experience_jobs = JSON.stringify(this.jobs)
			personalData.languages = JSON.stringify(this.languages)
			personalData.skills = JSON.stringify(this.skills)
			personalData.experience_time = JSON.stringify(this.experience)

			delete personalData.admin_comments
			delete personalData.anonymized
			delete personalData.birthdate
			delete personalData.chatbot_talk
			delete personalData.chatbot_token
			delete personalData.experience_by_profile
			delete personalData.file
			delete personalData.finished
			delete personalData.id_user
			delete personalData.image
			delete personalData.improvement
			delete personalData.progress
			delete personalData.recycle_data
			delete personalData.type
			delete personalData.validated

			ProfileAnalysisDataService.updatePersonalData(personalData).then(
				response => {
					if (response.status == 200) {
						this.edit = false
						this.success = true
						this.message = "Personal data updated successfully!"
						console.log('response')
						// this.$emit('recalculateResponse', false)
						window.location.reload()
					} else {
						this.edit = false;
						this.message = "There was an error while updating personal data. Please, try again later."
						this.$emit('cancel-event')
					}
				},
				error => {
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					this.edit = false
					this.message = "There was an error while updating personal data. Please, try again later."
					this.$emit('cancel-event')

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		cancel() {
			this.edit = false;
			this.$emit('cancel-event')
		},
		close() {
			document.getElementById("message").style.display = "none"
		}
	}
};
</script>

<style>
.add-icon {
	cursor: pointer !important;
}
</style>